import * as s from './call-to-action.styled';
import UnbounceButton from 'components/unbounceButton';
import Container from 'components/container';

interface TCallToAction {
    [otherProps: string]: any;
}

const CallToAction: React.FC<TCallToAction> = ({ ...props }) => (
    <s.CallToAction {...props}>
        <Container>
          <UnbounceButton variant='primary'>Activate Your Plan</UnbounceButton>
        </Container>
    </s.CallToAction>
);

export default CallToAction; 