import * as s from "./application.styled";
import Title from "components/title/title";
import Container from "components/container/container";
import ApplicationForm from "../application-form";
import Icon from "components/icons";
import { useEffect, useState } from "react";

const Application: React.FC = () => {
  const [noPromo, setNoPromo] = useState(
    window.location.href.includes("hppdf999")
  );

  useEffect(() => {
    setNoPromo(window.location.href.includes("hppdf999"));
  }, []);

  return (
    <s.Application>
      <Container>
        <s.ApplicationInner>
          <ApplicationForm></ApplicationForm>
          <s.ApplicationContent>
            <s.UnbouncedPositionWrapper>
              <s.UnbounceTitle>
                Haspro Warranty Offers <br /> A Plan For Everyone
              </s.UnbounceTitle>
              <s.UnbounceApplicationText>
                *Special Limited Time Intro Offer
              </s.UnbounceApplicationText>
            </s.UnbouncedPositionWrapper>

            <s.ApplicationRow className="hide-in-mobile">
              <s.ApplicationLabelWrap>
                <s.ApplicationLabel>
                  {noPromo ? (
                    <>
                      <s.ApplicationLabelFirstText>
                        Reg. Price
                      </s.ApplicationLabelFirstText>
                      <s.ApplicationLabelPrice old={true}>
                        $25
                      </s.ApplicationLabelPrice>
                    </>
                  ) : (
                    ""
                  )}
                  <s.ApplicationLabelFirstPrice>
                    {noPromo ? "$9.99" : "$0"}
                  </s.ApplicationLabelFirstPrice>

                  <s.ApplicationLabelFirstText>
                    {noPromo ? "Per Month" : "1st Month"}
                  </s.ApplicationLabelFirstText>
                  <s.ApplicationLabelLine>
                    {noPromo ? (
                      ""
                    ) : (
                      <>
                        <s.ApplicationLabelPrice old={true}>
                          $25
                        </s.ApplicationLabelPrice>
                        <s.ApplicationLabelPrice>
                          $9<sup>99</sup>
                        </s.ApplicationLabelPrice>
                      </>
                    )}
                  </s.ApplicationLabelLine>
                  {noPromo ? (
                    ""
                  ) : (
                    <s.ApplicationLabelInfo>
                      per month starting 30 days after enrollment
                    </s.ApplicationLabelInfo>
                  )}
                </s.ApplicationLabel>
                <s.ApplicationLabelDescription>
                  Cancel Anytime!
                </s.ApplicationLabelDescription>
              </s.ApplicationLabelWrap>
              <s.ApplicationRating>
                <Icon.Stars />
                <s.ApplicationRatingText>5 star rating</s.ApplicationRatingText>
              </s.ApplicationRating>
            </s.ApplicationRow>

            <s.ApplicationRating className="hide-in-desktop">
              <Icon.Stars />
              <s.ApplicationRatingText>5 Star Rating</s.ApplicationRatingText>
            </s.ApplicationRating>

            <s.UnbouncedApplicationList>
              {data.map(({ title }, index) => (
                <li key={index}>
                  <Icon.Check />
                  {title}
                </li>
              ))}
            </s.UnbouncedApplicationList>

            <s.PointingArrowWrapper className="hide-in-desktop">
              <s.ApplicationLabelWrap>
                <s.ApplicationLabel>
                  {noPromo ? (
                    <>
                      <s.ApplicationLabelFirstText>
                        Reg. Price
                      </s.ApplicationLabelFirstText>
                      <s.ApplicationLabelPrice old={true}>
                        $25
                      </s.ApplicationLabelPrice>
                    </>
                  ) : (
                    ""
                  )}
                  <s.ApplicationLabelFirstPrice>
                    {noPromo ? "$9.99" : "$0"}
                  </s.ApplicationLabelFirstPrice>
                  <s.ApplicationLabelFirstText>
                    {noPromo ? "Per Month" : "1st Month"}
                  </s.ApplicationLabelFirstText>
                  <s.ApplicationLabelLine>
                    {noPromo ? (
                      ""
                    ) : (
                      <>
                        <s.ApplicationLabelPrice old={true}>
                          $25
                        </s.ApplicationLabelPrice>
                        <s.ApplicationLabelPrice>
                          $9<sup>99</sup>
                        </s.ApplicationLabelPrice>
                      </>
                    )}
                  </s.ApplicationLabelLine>
                  {noPromo ? (
                    ""
                  ) : (
                    <s.ApplicationLabelInfo>
                      per month starting 30 days after enrollment
                    </s.ApplicationLabelInfo>
                  )}
                </s.ApplicationLabel>
                <s.ApplicationLabelDescription>
                  Cancel Anytime!
                </s.ApplicationLabelDescription>
              </s.ApplicationLabelWrap>
            </s.PointingArrowWrapper>
          </s.ApplicationContent>
        </s.ApplicationInner>
      </Container>
    </s.Application>
  );
};
export default Application;

const data = [
  {
    title: "Tires",
  },
  {
    title: "Electrical",
  },
  {
    title: "Glass",
  },
  {
    title: "Trip Interruption",
  },
  {
    title: "Towing",
  },
  {
    title: "Fuel Delivery",
  },
  {
    title: "Car Rental",
  },
  {
    title: "Lockout Services",
  },
  {
    title: "Brake System",
  },
  {
    title: "Flat Tire Replacement",
  },
];
