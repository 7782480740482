import { Container } from "components/container/container.styled";
import styled from "styled-components";
import { Card } from "components/card/card.styled";
import { FormControl } from "components/form-control/form-control.styled";

export const Checkout = styled.section`
  min-height: calc(100vh - 374px);
  display: flex;
  align-items: center;
  padding-top: 64px;
  & > div {
    width: 100%;
  }
  @media ${({ theme }) => theme.media?.extraSmall} {
    padding-top: 34px;
  }
  @media ${({ theme }) => theme.media?.large} {
    padding-top: 64px;
  }
`;

export const Text = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => (props.color === "light" ? "#969696" : "#575757")};
  margin-bottom: 24px;
`;

export const CheckoutForm = styled.form`
  button {
    max-width: 100%;
  }
  input {
    padding-left: 35px;
  }
`;

export const InputIcon = styled.div`
  width: 20px;
  position: absolute;
  top: calc(50% - 2px);
  left: 10px;
  transform: translateY(-50%);
`;

export const Separator = styled(Container)`
  border-bottom: 2px solid #e2e2e2;
  padding-bottom: 56px;
  @media ${({ theme }) => theme.media?.extraSmall} {
    margin-bottom: 23px;
  }
  @media ${({ theme }) => theme.media?.medium} {
    margin-bottom: 0px;
  }
`;

export const IconInputWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

export const AuthFormTitle = styled.h1`
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  margin-top: 0px;
  margin-bottom: 2px;
`;
export const AuthFormCard = styled(Card)`
  padding: 40px;
  a {
  }
  @media ${({ theme }) => theme.media?.extraSmall} {
    padding: 24px;
  }
  @media ${({ theme }) => theme.media?.medium} {
    padding: 40px;
  }
`;

export const AuthFormFormControl = styled(FormControl)`
  margin-bottom: 0px;
`;

export const AuthFormFormForgotPassword = styled.div`
  margin-top: 12px;
  a {
    color: ${({ theme }) => theme.colors?.primary};
  }
`;

export const ErrorFormCard = styled(Card)`
  padding: 40px;
  background: rgba(255, 124, 117, .4);!important;
  color: red;
  @media ${({ theme }) => theme.media?.extraSmall} { 
    padding: 24px;
    font-size: 14px;
    br{
      display: none;
    }
  }
  @media ${({ theme }) => theme.media?.small} {
    br{
      display: block;
    }
    font-size: 16px;
  }
  @media ${({ theme }) => theme.media?.medium} { 
    padding: 40px;
  }
`;
