import styled from "styled-components";

export const Testimonials = styled.section`
  padding: 18px 0 18px;
  background-color: ${({ theme }) => theme.colors.bg};
`;

export const Testimonial = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 12px;
    height: 64px;
    width: 64px;
  }
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }
`;

export const Title = styled.h2`
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 0;
  margin-bottom: 38px;
  text-align: center;
  &::before {
    position: absolute;
    content: "“";
    font-weight: 600;
    font-size: 60px;
    line-height: 72px;
    color: ${({ theme }) => theme.colors.lightBlue};
    z-index: -1;
    top: -23px;
    left: -7px;
  }
`;

export const PreTitle = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 50px;
  margin-top: 0;
  text-align: center;
`;

// unbounced
export const UnbouncedPreTitle = styled.h3`
  text-align: center;
  line-height: 20px;
  letter-spacing: 4px;
  font-weight: bolder;
  font-family: Montserrat;
  font-weight: 700;
  font-style: normal;
  font-size: 11px;
`;

export const UnbouncedTitle = styled.h2`
  text-align: center;
  line-height: 48px;
  font-style: italic;
  font-weight: 500;
  font-family: "PT Serif";
  font-size: 32px;
  color: #3e4144;
  margin:0;
`;

export const UnbouncedTestimonials = styled.section`
  width: max-content;
  margin: auto;
  img {
    width: 107px;
    margin: auto;
    margin-top: 40px!important;
  }
  h4 {
    text-align: center;
    line-height: 20px;
    letter-spacing: 2px;
    font-weight: bolder;
    font-family: Montserrat;
    font-weight: 700;
    font-style: normal;
    font-size: 10px;
    margin-top: 8px;
    margin-bottom: 0px;
  }
  p {
    line-height: 16px;
    text-align: center;
    font-family: "PT Serif";
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    margin-top: 0px;
  }
`;

export const UnbouncedQuote = styled.span`
  text-align: center;
  font-weight: bolder;
  font-family: "PT Serif";
  font-weight: 700;
  font-style: normal;
  font-size: 72px;
  line-height: 36px;
  display: block;
  margin: auto;
  margin-top: 51px;
`;
