import * as s from './select.styled';
import { CSSProperties } from 'react';
import { Controller } from 'react-hook-form';
import ReactSelect from "react-select";

interface TSelect {
  options: any;
  name: string;
  required?: boolean;
  control: any;
  validate?: any;
  label: string;
  className?: string;
}

const customStyles: any = {
  option: (provided: CSSProperties) => ({
    ...provided,
    color: '#414141',
    background: '#fff',
    fontSize: '14px',
    '&:hover': { cursor: 'pointer', background: '#E9E9E9' },
  }),
  menu: (provided: CSSProperties) => ({
    ...provided,
    width: '100%',
    height: 'auto',
    background: '#fff',
    borderRadius: '0px',
    overflow: 'hidden',
    marginTop: '3px',
    zIndex: '20',
  }),
  menuList: (provided: CSSProperties) => ({
    ...provided,
    height: 'auto',
    maxHeight: '280px',
    margin: 0,
    padding: 0,
  }),
  indicatorSeparator: (provided: CSSProperties) => ({
    ...provided,
    display: 'none',
  }),
  clearIndicator: (provided: CSSProperties) => ({
    ...provided,
    display: 'none',
  }),
  container: (provided: CSSProperties) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided: CSSProperties, state: any) => ({
    ...provided,
    borderRadius: '0px',
    fontSize: '14px',
    width: '100%',
    height: '34px',
    minHeight: '34px',
    color: '#414141',
    background: '#FFFFFF',
    boxShadow: 'none',
    fontWeight: '400',
    border: '1px solid #bbbbbb',
    // borderBottom: state.isFocused ? '1px solid #000' : state.hasValue ? '1px solid #000' : '1px solid #000',
    position: 'relative',
    '&:hover': { outline: 'none', cursor: 'pointer' },
  }),
  valueContainer: (provided: CSSProperties, state: any) => ({
    ...provided,
    overflow: state.isFocused ? 'hidden' : 'none',
  }),
  group: (provided: CSSProperties) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  placeholder: (provided: CSSProperties, state: any) => ({
    ...provided,
    padding: '0',
    // position: 'absolute',
    background: '#FFFFFF',
    color: !state.isFocused ? '#6D6D6D' : '#414141',
    fontWeight: 400,
  }),
  indicatorsContainer: (provided: CSSProperties) => ({
    ...provided,
  })
};

const UnbounceSelect: React.FC<TSelect> = ({ options, required, validate, label, control, name, ...props }) => (
  <Controller
    name={name}
    control={control}
    rules={validate}
    render={({ field }) => <ReactSelect
      {...field}
      styles={customStyles}
      options={options}
      placeholder={label}
      {...props}
    />}
  />
)

export default UnbounceSelect;