import * as s from "./setEmail.styled";

import { Container } from "components/container/container.styled";
import Button from "components/button";

import { useForm } from "react-hook-form";

import Input from "components/input";
import FormError from "components/form-error";
import { useEffect, useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router";
import Loader from "components/loader";

import EmailIcon from "assets/icons/email.png";

type FormData = {
  email: string;
  password: string;
};

const SetEmail: React.FC = () => {
  const navigate = useNavigate();
  const [loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const {
    register,
    setValue,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>();
  const onSubmit = (data: any) => {
    setLoadingStatus(true);
    axios
      .post("/api/auth/forgot", data)
      .then(function (response) {
        setLoadingStatus(false);
        reset();
        navigate(`/email-success/${data.email}`);
      })
      .catch(function (error) {
        setLoadingStatus(false);
      });
  };

  return (
    <>
      {loadingStatus ? <Loader /> : ""}
      <s.Checkout>
        <Container width="512px">
        {/* {window.location.href.includes("again") ? (
            <s.ErrorFormCard>
              This password reset link is no longer valid. 
             <br/> Please a new password reset link.
            </s.ErrorFormCard>
          ) : (
            ""
          )} */}
          <s.AuthFormCard>
            <s.AuthFormTitle>Enter your email</s.AuthFormTitle>
            <s.Text>We will send you instructions to reset the password.</s.Text>
            <s.CheckoutForm onSubmit={handleSubmit(onSubmit)}>
              <s.IconInputWrapper>
                <s.AuthFormFormControl>
                  <Input
                    label="Email"
                    name={"email"}
                    register={register}
                    type="email"
                    validate={{
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    }}
                  />
                  <s.InputIcon>
                    <img src={EmailIcon} width="91" />
                  </s.InputIcon>
                </s.AuthFormFormControl>
                {errors.email && <FormError>{errors.email.message}</FormError>}
              </s.IconInputWrapper>

              <Button variant="primary" type="submit">
                Send
              </Button>
            </s.CheckoutForm>
          </s.AuthFormCard>
        </Container>
      </s.Checkout>
      <s.Separator></s.Separator>
    </>
  );
};

export default SetEmail;
