import * as s from './testimonial.styled';
import Container from 'components/container';
import testimonial from 'assets/images/testimonial.png';

const Testimonial: React.FC = () => (
  <s.Testimonials>
    <Container width="600px">
    <s.UnbouncedPreTitle>WHAT OUR CUSTOMERS SAY</s.UnbouncedPreTitle>
    <s.UnbouncedQuote>“</s.UnbouncedQuote>
    <s.UnbouncedTitle>This is way too inexpensive to pass it up! For this price it is not worth not having this!</s.UnbouncedTitle>
    </Container>
    <s.UnbouncedTestimonials>
      <img src={testimonial} alt="Dave"/>
      <div>
        <h4>DAVE</h4>
        <p>2012 Chevrolet Cobalt</p>
      </div>
    </s.UnbouncedTestimonials>
  </s.Testimonials>
)

export default Testimonial;