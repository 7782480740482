import React, { useEffect, useState } from "react";
import * as s from "./loader.styled";

interface TLoader {
  description?: React.ReactNode;
  nextDescription?: React.ReactNode;
}

const Loader: React.FC<TLoader> = ({ description, nextDescription }) => {
  const [msg, setMsg] = useState(description);

  useEffect(() => {
    console.log(msg);
    if(msg){
      setTimeout(() => {
        setMsg(nextDescription)
      }, 3000);
    }
}, [msg]);

  return (
    <s.Layout>
      <s.Wrapper>
        <s.Dot />
        <s.Description>{msg}</s.Description>
      </s.Wrapper>
    </s.Layout>
  );
};

export default Loader;
