import { useForm } from "react-hook-form";

import * as s from "./application-form.styled";
import FormControl from "components/form-control";
import Subtitle from "components/subtitle";
import UnbounceButton from "components/unbounceButton";
import Input from "components/input";
import UnbounceInput from "components/unbounceInput";
import UnbounceSelect from "components/unbounceSelect";
import FormError from "components/form-error";
import Loader from "components/loader";

import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";

type FormData = {
  year: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  zip: string;
  mileage: string;
  vehicleMake: string;
  vehicleYear: string;
  vehicleModel: string;
  errorMessages: object;
};

const ApplicationForm: React.FC = () => {
  // const [email, setEmail] = useState('');
  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);

  const [phoneValidation, setPhoneValidation] = useState(true);
  const [firstNameValidation, setFirstNameValidation] = useState(true);
  const [lastNameValidation, setLastNameValidation] = useState(true);
  const navigate = useNavigate();

  const {
    register,
    setValue,
    getValues,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  useEffect(() => {
    setLoadingStatus(true);
    axios
      .get(`/api/vehicle/GetMakes`)
      .then(function (response) {
        setLoadingStatus(false);
        const options = response.data.map(function (row: any) {
          return { value: row.id, label: row.name };
        });
        setVehicleMakes(options);
      })
      .catch(function (error) {
        setLoadingStatus(false);
        // setErrorMessage(error.response?.data.message);
      });
  }, []);

  const subscription = watch("vehicleMake");
  useEffect(() => {
    if ((subscription as any)?.value) {
      getVehicleModels((subscription as any).value);
    }
    // return () => subscription.unsubscribe();
  }, [subscription]);

  const watchPhone = watch("phone");
  useEffect(() => {
    if (!phoneValidation) {
      const d = getValues().phone as any;
      if (d.includes("_")) {
        setPhoneValidation(false);
      } else {
        setPhoneValidation(true);
      }
    }
  }, [watchPhone]);

  const watchFirstName = watch("first_name");
  useEffect(() => {
    if (!firstNameValidation) {
      const d = getValues().first_name as any;
      if (d.includes("_")) {
        setFirstNameValidation(false);
      } else {
        setFirstNameValidation(true);
      }
    }
  }, [watchFirstName]);

  const watchLastName = watch("last_name");
  useEffect(() => {
    if (!lastNameValidation) {
      const d = getValues().last_name as any;
      if (d.includes("_")) {
        setLastNameValidation(false);
      } else {
        setLastNameValidation(true);
      }
    }
  }, [watchLastName]);

  const getVehicleModels = (id: any) => {
    setVehicleModels([]);
    setValue("vehicleModel", "");
    axios
      .get(`/api/vehicle/GetModels?makeid=${id}`)
      .then(function (response) {
        // setLoadingStatus(false);
        const options = response.data.map(function (row: any) {
          return { value: row.id, label: row.name };
        });
        setVehicleModels(options);
      })
      .catch(function (error) {
        // setLoadingStatus(false);
        // setErrorMessage(error.response?.data.message);
      });
  };

  const onSubmit = (data: any) => {
    if (data.phone.includes("_")) {
      setPhoneValidation(false);
      return;
    } else {
      setPhoneValidation(true);
    }

    if (data.first_name.trim().length === 0) {
      setFirstNameValidation(false);
      return;
    } else {
      setFirstNameValidation(true);
    }

    if (data.last_name.trim().length === 0) {
      setLastNameValidation(false);
      return;
    } else {
      setLastNameValidation(true);
    }

    const reqData = {
      vehicleYear: data.year.value,
      vehicleMileage: data.vehicleYear,
      brandId: data.vehicleMake.value,
      modelId: data.vehicleModel.value,
      brandName: data.vehicleMake.label,
      modelName: data.vehicleModel.label,
      firstName: data.first_name,
      lastName: data.last_name,
      company: data.vehicleBrand,
      email: data.email,
      phone: data.phone,
      zipCode: data.zip || 0,
    };

    setLoadingStatus(true);
    axios
      .get(`/api/customer/CheckExistingCustomer?customerEmail=${reqData.email}`)
      .then(function (response) {
        let leadData = {
          // id: "",
          // created: "",
          // modified: "",
          firstName: data.first_name,
          lastName: data.last_name,
          company: data.vehicleBrand,
          email: data.email,
          phone: data.phone,
          zipCode: data.zip || 0,
          vehicleYear: data.year.value,
          vehicleMileage: parseInt(data.vehicleYear),
          vehicleBrandId: data.vehicleMake.value,
          vehicleModelId: data.vehicleModel.value,
          siteName: window.location.origin,
          deleted: false,
        };
        axios
          .post(`/api/lead`, leadData)
          .then(function (response) {
            setLoadingStatus(false);
            localStorage.setItem("guestData", JSON.stringify(reqData));
            // navigate(
            //   window.location.href.includes("blcrpt001")
            //     ? "/blcrpt001/checkout"
            //     : "/checkout"
            // );

            let route = '';

            // if(window.location.href.includes('blcrpt001')) {
            //   route += '/blcrpt001';
            // }

            if(window.location.href.includes('hppdf999')) {
              route += '/hppdf999';
            }

            route += '/checkout';

            navigate(route);
          })
          .catch(function (error) {
            setLoadingStatus(false);
          });
      })
      .catch(function (error) {
        setLoadingStatus(false);
        setErrorMessage(error.response?.data.message);
      });
  };

  const deadLead = () => {
    axios
      .post(`/api/lead`)
      .then(function (response) {})
      .catch(function (error) {});
  };

  return (
    <>
      {loadingStatus ? <Loader /> : ""}
      <s.ApplicationFormWrap id="activationForm">
        <s.UnbounceSubtitle>To Activate Your Plan</s.UnbounceSubtitle>
        <s.UnbounceSubtitle>Fill Out The Information Below</s.UnbounceSubtitle>
        <s.ApplicationForm onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <UnbounceSelect
              className="custom-select"
              options={years}
              name="year"
              control={control}
              label="Vehicle Year*"
              validate={{ required: "Year is required" }}
            />
            {errors.year && <FormError>{errors.year.message}</FormError>}
          </FormControl>
          <FormControl>
            <UnbounceSelect
              className="custom-select"
              options={vehicleMakes}
              control={control}
              label="Vehicle Make*"
              name="vehicleMake"
              validate={{ required: "Vehicle Make is required" }}
            />
            {errors.vehicleMake && (
              <FormError>{errors.vehicleMake.message}</FormError>
            )}
          </FormControl>
          {vehicleModels?.length > 0 ? (
            <FormControl>
              <UnbounceSelect
                className="custom-select"
                options={vehicleModels}
                control={control}
                label="Vehicle Model*"
                name="vehicleModel"
                validate={{ required: "Vehicle Model is required" }}
              />
              {errors.vehicleModel && (
                <FormError>{errors.vehicleModel.message}</FormError>
              )}
            </FormControl>
          ) : (
            ""
          )}
          <FormControl>
            <UnbounceInput
              label="Vehicle Mileage*"
              mask="millage"
              name="vehicleYear"
              register={register}
              type="number"
              min="0"
              validate={{
                required: "Vehicle Mileage is required",
              }}
            />
            {errors.vehicleYear && (
              <FormError>{errors.vehicleYear.message}</FormError>
            )}
          </FormControl>
          <FormControl>
            <UnbounceInput
              label="First Name*"
              name={"first_name"}
              register={register}
              type="text"
              validate={{
                required: "First Name is required",
                pattern: {
                  value: /^[a-zA-Z ]{2,30}$/, // /^(?=.*[A-Z])/gm
                  message: "Invalid first name",
                },
              }}
            />
            {!firstNameValidation && (
              <FormError>First Name is required</FormError>
            )}
            {errors.first_name && (
              <FormError>{errors.first_name.message}</FormError>
            )}
          </FormControl>
          <FormControl>
            <UnbounceInput
              label="Last Name*"
              name={"last_name"}
              register={register}
              type="text"
              validate={{
                required: "Last Name is required",
                pattern: {
                  value: /^[a-zA-Z ]{2,30}$/, // /^(?=.*[A-Z])/gm
                  message: "Invalid last name",
                },
              }}
            />
            {!lastNameValidation && (
              <FormError>Last Name is required</FormError>
            )}
            {errors.last_name && (
              <FormError>{errors.last_name.message}</FormError>
            )}
          </FormControl>
          <FormControl>
            <UnbounceInput
              label="Phone Number*"
              name={"phone"}
              mask="phone"
              register={register}
              type="tel"
              required={true}
              validate={{ required: "Phone Number is required" }}
            />
            {!phoneValidation && <FormError>Enter valid phone</FormError>}
            {errors.phone && <FormError>{errors.phone.message}</FormError>}
          </FormControl>
          <FormControl>
            <UnbounceInput
              label="Email*"
              name={"email"}
              register={register}
              type="email"
              validate={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
            />
            {errors.email && <FormError>{errors.email.message}</FormError>}
          </FormControl>
          {/* <FormControl size="large">
            <UnbounceInput
              label="Zip Code"
              mask="zip"
              name={"zip"}
              register={register}
              type="text"
            />
          </FormControl> */}
          <s.PointingArrowWrapper>
            <UnbounceButton
              className="noHover"
              full="100"
              variant="primary"
              type="submit"
            >
              Activate Your Plan
            </UnbounceButton>
          </s.PointingArrowWrapper>
          <FormError>{errorMessage} </FormError>
        </s.ApplicationForm>

        <s.ApplicationFormText>
          *By submitting this form, I am providing my authorization for Haspro
          Warranty to contact me by email with the information provided above.
        </s.ApplicationFormText>
      </s.ApplicationFormWrap>
    </>
  );
};
export default ApplicationForm;

const currenctYear = new Date().getFullYear() + 1;
const years = Array(currenctYear - 1989)
  .fill("")
  .map((v, idx) => {
    const year = (currenctYear - idx).toString();
    return { value: year, label: year };
  });

const errorMessages = {
  rerquired: "Field is required",
  email: "Please enter valid email adress",
};
