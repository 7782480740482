import * as s from './input.styled';

import InputMask from 'react-input-mask';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';

import Icon from 'components/icons';
import { useEffect } from 'react';

interface TInput extends React.InputHTMLAttributes<HTMLInputElement> {
    register: UseFormRegister<any>;
    label: string;
    name: string;
    mask?: 'phone' | 'zip' | 'vin' | 'mmyy' | 'cardNumber' | 'millage' | 'cvv';
    width?: string;
    validate?: any;
}

const UnbounceInput: React.FC<TInput> = ({ label, register, name, type, validate, mask, width, ...props }) => {
    const wmi = /[a-zA-Z0-9]/;
    const numberVin = /[0-9]/;
    const regExpVinNumber = [...Array(13).fill(wmi), ...Array(4).fill(numberVin)];

    const handleCursorChange =(e:any)=>{
        const index = e?.target.value.split('_')[0].length;
        e?.target?.setSelectionRange(index,index);
    }

    return (
        <>
            {!mask ? <s.Input
                placeholder={label}
                autoComplete='on'
                type={type}
                {...register(name, validate)}
                {...props}
            />
                :
                mask === 'phone' ?
                    <InputMask mask='999-999-9999' type={type} {...register(name, validate)} onClick={(e)=>handleCursorChange(e)} >
                        {(inputProps: any) => <s.Input {...inputProps} placeholder={label} />}
                    </InputMask>
                    : mask === 'zip' ?
                        <InputMask mask='99999' {...register(name, validate)} onClick={(e)=>handleCursorChange(e)}>
                            {(inputProps: any) => <s.Input id="zipcode" {...inputProps} placeholder={label} />}
                        </InputMask>
                        : mask === 'mmyy' ?
                            <InputMask mask='99/99' {...register(name, validate)}>
                                {(inputProps: any) => <s.Input {...inputProps} data-testid="cardExpireDate" placeholder={label} />}
                            </InputMask>
                            : mask === 'cardNumber' ?
                                <InputMask mask='9999-9999-9999-9999' {...register(name, validate)}>
                                    {(inputProps: any) => <s.Input {...inputProps} placeholder={label} />}
                                </InputMask>
                                : mask === 'millage' ?
                                    <InputMask mask='999999' {...register(name, validate)} onClick={(e)=>handleCursorChange(e)}>
                                        {(inputProps: any) => <s.Input {...inputProps} placeholder={label} />}
                                    </InputMask>
                                    : mask === 'cvv' ?
                                        <InputMask mask='999' {...register(name, validate)}>
                                            {(inputProps: any) => <s.Input {...inputProps} placeholder={label} />}
                                        </InputMask>
                                        :
                                        <InputMask mask={regExpVinNumber}>
                                            {(inputProps: any) => <s.Input {...inputProps} placeholder={label} />}
                                        </InputMask>
            }
            {/* <s.Label focused={focused || Boolean(value)}>{label}</s.Label> */}
            {/* {type === 'password' && (
                <s.Icon onClick={() => setPasswordShown(!showPassword)}>
                    <Icon.Check />
                </s.Icon>
            )} */}
        </>
    );
};

export default UnbounceInput;
