import React from 'react';
import * as s from './button.styled';

interface TButton extends React.ButtonHTMLAttributes<HTMLButtonElement>  {
    variant?: 'primary' | 'secondary' | 'link' | 'warning';
    full?: '100'
}

const UnbounceButton: React.FC<TButton> = ({ children, variant = 'primary', ...props }) => (
    <s.Button  variant={variant} {...props}>
        {children} 
    </s.Button>
);

export default UnbounceButton;