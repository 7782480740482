import styled from 'styled-components';

export const Button = styled.button<{ variant?: 'primary' | 'secondary' | 'link' | 'warning', full?: '100' }>`
    // width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    // border: none;
    // outline: none;
    // cursor: pointer;
    // border-radius: 23px;
    // text-transform: uppercase;
    // font-weight: 800;
    // padding: 14px 32px;
    // max-width: 338px;

    // display: block;
    border-style: solid;
    border-radius: 100px;
    width: 264px;
    height: 64px;
    background: rgba(7,204,135,1);
    box-shadow: none;
    text-shadow: none;
    color: #fff;
    border-width: 1px;
    border-color: #C9C9C9;
    font-size: 20px;
    line-height: 24px;
    font-family: Montserrat;
    font-style: normal;
    text-align: center;
    background-repeat: no-repeat;
    cursor: pointer;

    font-size: 20px;
    line-height: 24px;
    font-weight: 900;
    font-family: Montserrat;
    font-style: normal;

    &:hover{
      background: rgba(255,255,255,1);
      color: #1279FD;
      border-color: #1279FD;
    }

    &.noHover:hover{
      background: rgba(7,204,135,1);
      color: #fff;
      border-color: #C9C9C9;
    }
`;