import styled, { keyframes } from "styled-components";

export const Layout = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 101;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const BounceAnimation = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;

export const Dot = styled.div<{ delay?: 0 }>`
  width: 80px;
  height: 80px;
  border: 4px solid ${({ theme }) => theme.colors?.white};
  border-radius: 50%;
  border-top: 4px solid ${({ theme }) => theme.colors?.primary};
  animation: ${BounceAnimation} 0.8s linear infinite;
`;

export const Description = styled.h1`
  color: white;
  width: 100%;
  display: block;
  text-align: center;
`;
