import styled from "styled-components";
import ArrowDesktop from "../../../assets/icons/active-arrow-desktop.svg";
import ArrowDesktopBlue from "../../../assets/icons/active-arrow-desktop-blue.png";
import { Input } from "components/input/input.styled";

export const ApplicationFormWrap = styled.div`
  padding-top: 12px;
  padding-bottom: 24px;
  background-color: #eeeeee;
  // border-radius: 8px;
  margin-bottom: 45px;
  @media ${({ theme }) => theme.media.extraSmall} {
    padding: 40px;
    max-width: 320px;
    margin 0 auto;
    margin-top: 32px;
  }
  @media ${({ theme }) => theme.media.medium} {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 20px;
  }
  @media ${({ theme }) => theme.media.large} {
    padding: 40px;
  }
`;

export const ApplicationForm = styled.form`
  margin-top: 16px;
  margin-bottom: 24px;
  @media ${({ theme }) => theme.media.extraSmall} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media ${({ theme }) => theme.media.medium} {
    display: block;
  }
`;

export const ApplicationFormText = styled.p`
  font-size: 10px;
  font-family: Montserrat;
  font-family: Montserrat;
  font-weight: 300;
  font-style: normal;
  line-height: 11px;
  text-align: center;
  @media ${({ theme }) => theme.media.medium} {
    margin: 32px 0 0 0;
  }
  @media ${({ theme }) => theme.media.large} {
    margin: 48px 0 0 0;
  }
`;

export const PointingArrowWrapper = styled.div`
  button {
    width: 100%;
  }
  
  @media ${({ theme }) => theme.media?.extraSmall} {
    width: 100%;
    position: relative;
    &:after {
      content: "";
      width: 78px;
      height: 78px;
      position: absolute;
      right: 0px;
      top: 50%;
      background-image: url(${!window.location.href.includes("blcrpt001")
        ? ArrowDesktop
        : ArrowDesktopBlue});
      background-size: contain;
      transform: translate(calc(100% - 8px), calc(-100% + 10px));
    }
  }
  
`;

export const UnbounceSubtitle = styled.h2<{ alignment?: string }>`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors?.primary};
  margin-top: 0;
  text-align: ${(props) => (props.alignment ? props.alignment : "")};
  margin-bottom: 0px;
  color: rgb(51, 204, 153);
  font-style: normal;
  text-align: center;
  font-family: Montserrat;

  &:first-child {
    font-weight: 900;
    font-size: 20px;
  }
  &:nth-child(2) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
`;
