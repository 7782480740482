import * as s from "./benefits.styled";
import Container from "components/container";

import tire from "assets/images/utire.png";
import towing from "assets/images/utowing.png";
import rental from "assets/images/urental.png";
import brake from "assets/images/ubrake.png";
import electrical from "assets/images/uelectrical.png";
import trip from "assets/images/utrip.png";
import glass from "assets/images/uglass.png";
import fuel from "assets/images/ufuel.png";
import lock from "assets/images/ulock.png";
import tires from "assets/images/utires.png";

import tireBlue from "assets/images/tire-blue.png";
import towingBlue from "assets/images/towing-blue.png";
import rentalBlue from "assets/images/rental-blue.png";
import brakeBlue from "assets/images/brake-blue.png";
import electricalBlue from "assets/images/electrical-blue.png";
import tripBlue from "assets/images/trip-blue.png";
import glassBlue from "assets/images/glass-blue.png";
import fuelBlue from "assets/images/brake-blue.png";
import lockBlue from "assets/images/lock-blue.png";
import tiresBlue from "assets/images/tires-blue.png";
import { useEffect, useRef } from "react";
import Subtitle from "components/subtitle";

const Benefits: React.FC = () => {
  const myRef = useRef(null);

  const executeScroll = () => {
    (myRef?.current as any).scrollIntoView();
  };

  useEffect(() => {
    if (window?.location?.href?.includes("faq")) {
      executeScroll();
    }
  }, []);
  return (
    <s.Benefits ref={myRef}>
      <s.UnbounceContainer>
        <Subtitle alignment="center">The Following Benefits are Included</Subtitle>
        <br />
        <br />
        <s.BenefitsList>
          {data.map(({ image, imageBlue, title, additional, shortDesc, description }, index) => (
            <s.UnbounceBenefitsListItem key={index}>
                <img
                  src={
                    window.location.href.includes("blcrpt001")
                      ? imageBlue
                      : image
                  }
                  alt="HasPro"
                />
              <h3>{title}</h3>
              <s.Additional>{additional}</s.Additional>
              <s.ShortDesc>{shortDesc}</s.ShortDesc>
              <p>{description}</p>
            </s.UnbounceBenefitsListItem>
          ))}
        </s.BenefitsList>
      </s.UnbounceContainer>
    </s.Benefits>
  );
};

export default Benefits;

const data = [
  {
    image: tire,
    imageBlue: tireBlue,
    title: "Flat Tire Replacement",
    description:
      "For when you’re driving and you get a flat tire, we will come and put your spare on.",
  },
  {
    image: glass,
    imageBlue: glassBlue,
    title: "Glass",
    description:
      "When a pebble hits your windshield from the big rig in front of you, we’ll repair it. ",
  },
  {
    image: towing,
    imageBlue: towingBlue,
    title: "Towing",
    description:
      "When your alternator goes out on you, and you need to get it towed.",
  },
  {
    image: rental,
    imageBlue: rentalBlue,
    title: "Car Rental",
    description:
      "You still need to get around when your vehicle is in the shop.",
  },
  {
    image: brake,
    imageBlue: brakeBlue,
    title: "Brake System",
    additional: "(For vehicles with less than 150K miles.)",
    shortDesc: '(ABS wheel speed sensor)',
    description:
      `When your brakes aren’t working it’s no laughing matter`,
  },
  {
    image: electrical,
    imageBlue: electricalBlue,
    title: "Electrical",
    additional: "(Windshield wiper motor, turn signal switch, Horn)",
    description:
      "It’s pouring outside and you can’t use the car because your windshield wipers are not working",
  },
  {
    image: trip,
    imageBlue: tripBlue,
    title: "Trip Interruption",
    description:
      "If you take a trip tomorrow and break down we will pay for your lunch, dinner, and even your hotel if you need to stay overnight until your vehicle is fixed.",
  },
  {
    image: fuel,
    imageBlue: fuelBlue,
    title: "Fuel Delivery",
    description:
      "If you run out of fuel, we'll travel out to you and get you on your way with some gas.",
  },
  {
    image: lock,
    imageBlue: lockBlue,
    title: "Lock Out Services",
    description:
      "Left your keys in your locked vehicle? No worries, we'll get you back in the vehicle  - 24/7.",
  },
  {
    image: tires,
    imageBlue: tiresBlue,
    title: "Tires",
    description:
      "You get a flat tire, we’ll pay to repair or replace the tire.",
  },
];
