import * as s from "./set-password.styled";

import { Container } from "components/container/container.styled";
import Button from "components/button";

import { useForm } from "react-hook-form";

import Input from "components/input";
import FormError from "components/form-error";
import { useEffect, useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router";
import Loader from "components/loader";

import KeyIcon from "assets/icons/key.png";

type FormData = {
  verifyPassword: string;
  password: string;
};

const ResetPassword: React.FC = () => {
  const [urlData, setUrlData] = useState<any>(null);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg]: any = useState(null);
  const [isNotSame, setIsNotSame]: any = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    let urlParams = new URL(window.location.href).searchParams;
    localStorage.clear();
    const data = {
      email: urlParams.get("email")
    };
    localStorage.setItem("emailData", JSON.stringify(data));
  }, []);

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const onSubmit = (data: any) => {
    const urlData = JSON.parse(
      localStorage.getItem("emailData") as string
    ) as Object;

    const reqData = {
      email: (urlData as any).email,
      password: data.password,
      verifyPassword: data.verifyPassword,
    };

    setIsNotSame(false);
    if (data.password === data.verifyPassword) {
      if (checkPassword(data.password)) {
        setLoadingStatus(true);
        axios
          .post("/api/auth/set-password", reqData)
          .then(function (response) {
            setLoadingStatus(false);
            navigate(window.location.href.includes('blcrpt001')?"/blcrpt001/login":"/login");
          })
          .catch(function (error) {
            setLoadingStatus(false);
          });
      }
    } else {
      setIsNotSame(true);
    }
  };

  const checkPassword = (str: any) => {
    if (!new RegExp(/^(?=.*[a-z])/gm).test(str)) {
      setErrorMsg("Password not includes lowercase letter");
      return false;
    } else if (!new RegExp(/\d/).test(str)) {
      setErrorMsg("Password not includes number");
      return false;
    } else {
      setErrorMsg(null);
      return true;
    }
  };

  return (
    <>
      {loadingStatus ? <Loader /> : ""}
      <s.Checkout>
        <Container width="512px">
          <s.AuthFormCard>
            <s.AuthFormTitle>Create a password</s.AuthFormTitle>
            <s.Text>Please enter your password to the fields below</s.Text>
            <s.CheckoutForm onSubmit={handleSubmit(onSubmit)}>
              <s.IconInputWrapper>
                <s.AuthFormFormControl>
                  {/* <Input label="Password" name={'password'} register={register} type="password" required={true} validate={{ required: 'Password is required' }} /> */}
                  <Input
                    label="Password"
                    name={"password"}
                    register={register}
                    type="password"
                    validate={{
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Min. length is 8 character",
                      },
                      pattern: {
                        value: /^(?=.*[A-Z])/gm,
                        message: "Password not includes uppercase letter",
                      },
                    }}
                  />

                  <s.InputIcon>
                    <img src={KeyIcon} width="91" />
                  </s.InputIcon>
                </s.AuthFormFormControl>
                {/* {errors.password && (<FormError>{errors.password.message}</FormError>)} */}

                {!errors.password?.message && errorMsg ? (
                  <FormError>{errorMsg}</FormError>
                ) : (
                  ""
                )}

                {errors.password && (
                  <FormError>{errors.password.message}</FormError>
                )}
              </s.IconInputWrapper>

              <s.IconInputWrapper>
                <s.AuthFormFormControl>
                  <Input
                    label="Confirm"
                    name={"verifyPassword"}
                    register={register}
                    type="password"
                    required={true}
                    validate={{ required: "Verify password is required" }}
                  />
                  <s.InputIcon>
                    <img src={KeyIcon} width="91" />
                  </s.InputIcon>
                </s.AuthFormFormControl>
                {errors.verifyPassword && (
                  <FormError>{errors.verifyPassword.message}</FormError>
                )}
                {isNotSame && (
                  <FormError>Verify password is not same</FormError>
                )}
              </s.IconInputWrapper>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </s.CheckoutForm>
          </s.AuthFormCard>
        </Container>
      </s.Checkout>
      <s.Separator></s.Separator>
    </>
  );
};

export default ResetPassword;
