import * as s from "./repair/repair.styled";
import Repair from "./repair";
import CallToAction from "./call-to-action";
import Testimonial from "./testimonial";
import Benefits from "./benefits";
import Application from "./application";
import ComparisonChart from "./comparisonChart";

import CarImg from "assets/images/car-img.png";
import { useState } from "react";

const UnbounceLandingV2: React.FC = () => {
  const [isV3, setV3] = useState(window.location.href.includes("vs003"));

  const scrollToActivationForm = () => {
    document
      .getElementById("activationForm")
      ?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Application />
      {isV3 ? <ComparisonChart /> : ""}

      <Repair />
      <CallToAction
        className="hide-in-mobile"
        onClick={() => scrollToActivationForm()}
      />
      <Testimonial />

      <CallToAction onClick={() => scrollToActivationForm()} />
      <s.RepairContent className="show-in-mobile">
        <s.UnbounceSubtitle>
          Cost of repairs without Haspro Warranty:
        </s.UnbounceSubtitle>
        <s.UnbounceRepairCosts>
          <img src={CarImg} />
        </s.UnbounceRepairCosts>
      </s.RepairContent>
      <Benefits />
      <CallToAction onClick={() => scrollToActivationForm()} />
    </>
  );
};

export default UnbounceLandingV2;
