import * as s from "./login.styled";

import { Container } from "components/container/container.styled";
import Button from "components/button";

import { useForm } from "react-hook-form";

import Input from "components/input";
import FormError from "components/form-error";
import { useEffect, useState } from "react";

import axios from "axios";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";
import Loader from "components/loader";

import EmailIcon from "assets/icons/email.png";
import KeyIcon from "assets/icons/key.png";

type FormData = {
  username: string;
  password: string;
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const onSubmit = (data: any) => {
    setLoadingStatus(true);
    axios
      .post("/api/auth/login", data)
      .then(function (response) {
        setLoadingStatus(false);
        localStorage.setItem("userData", JSON.stringify(response.data));
        
        navigate(window.location.href.includes('blcrpt001')?"/blcrpt001/user-cabinet": "/user-cabinet");
      })
      .catch(function (error) {
        setLoadingStatus(false);
      });
  };

  return (
    <>
      {loadingStatus ? <Loader /> : ""}
      <s.Checkout>
        <Container width="512px">
          <s.AuthFormCard>
            <s.AuthFormTitle>Welcome</s.AuthFormTitle>
            <s.CheckoutForm onSubmit={handleSubmit(onSubmit)}>
              <s.IconInputWrapper>
                <s.AuthFormFormControl>
                  <Input
                    label="Email"
                    name={"username"}
                    register={register}
                    type="email"
                    validate={{
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    }}
                  />
                  <s.InputIcon>
                    <img src={EmailIcon} width="91" />
                  </s.InputIcon>
                </s.AuthFormFormControl>
                {errors.username && (
                  <FormError>{errors.username.message}</FormError>
                )}
              </s.IconInputWrapper>

              <s.IconInputWrapper>
                <s.AuthFormFormControl>
                  <Input
                    label="Password"
                    name={"password"}
                    register={register}
                    type="password"
                    required={true}
                    validate={{ required: "Password is required" }}
                  />
                  <s.InputIcon>
                    <img src={KeyIcon} width="91" />
                  </s.InputIcon>
                </s.AuthFormFormControl>
                {errors.password && (
                  <FormError>{errors.password.message}</FormError>
                )}
              </s.IconInputWrapper>

              <Button variant="primary" type="submit">
                Login
              </Button>
            </s.CheckoutForm>

            <s.AuthFormFormForgotPassword>
              <s.Text>
                Forgot your password?{" "}
                <NavLink to={window.location.href.includes('blcrpt001')?"/blcrpt001/reset-password-step1":"/reset-password-step1"}>Reset</NavLink>
              </s.Text>
            </s.AuthFormFormForgotPassword>
          </s.AuthFormCard>
        </Container>
      </s.Checkout>
      <s.Separator></s.Separator>
    </>
  );
};

export default Login;
