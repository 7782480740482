import title from "components/title";
import styled from "styled-components";

import ArrowDesktop from "../../../assets/icons/active-arrow-desktop.svg";
import ArrowDesktopBlue from "../../../assets/icons/active-arrow-desktop-blue.png";

export const Application = styled.section`
  @media ${({ theme }) => theme.media?.extraSmall} {
    padding-top: 0px;
  }
  @media ${({ theme }) => theme.media?.small} {
    margin-top: 0px;
    padding-top: 0px;
  }
  @media ${({ theme }) => theme.media?.medium} {
    // margin-top: -56px;
    padding-top: 0px;
  }
  padding-top: ${({ theme }) => theme.grid?.gridGutterMedium};
  padding-bottom: ${({ theme }) => theme.grid?.gridGutter};
`;

export const ApplicationInner = styled.div`
  @media ${({ theme }) => theme.media?.extraSmall} {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  @media ${({ theme }) => theme.media?.medium} {
    display: grid;
    grid-template-columns: calc(45% - 28px) calc(55% - 28px);
    grid-gap: 56px;
  }
  @media ${({ theme }) => theme.media?.large} {
    grid-template-columns: 386px auto;
    grid-gap: 26px;
  }
`;

export const ApplicationText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 0;
  margin-bottom: 18px;
  @media ${({ theme }) => theme.media?.medium} {
    text-align: center;
  }
  @media ${({ theme }) => theme.media?.large} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const ApplicationContent = styled.div`
  widht: max-content;
  h1 {
    margin-bottom: 19px;
  }
  @media ${({ theme }) => theme.media?.medium} {
    h1 {
      text-align: center;
    }
  }
`;
export const ApplicationLabelWrap = styled.div`
  max-width: 173px;
  // margin: 0 auto;
  @media ${({ theme }) => theme.media?.extraSmall} {
    margin: auto;
  }
  @media ${({ theme }) => theme.media?.medium} {
    margin: 0px;
    margin-right: 16px;
  }
  @media ${({ theme }) => theme.media?.large} {
    margin: 0px;
    margin-right: 64px;
  }
`;
export const ApplicationLabelDescription = styled.p`
  text-align: center;
  font-style: normal;
  line-height: 18px;
  margin: 8px 0 0 0;

  font-weight: 300;
  font-family: Montserrat;
  font-size: 16px;
  color: rgb(142, 146, 145);
  background-color: rgb(255, 255, 255);
`;

export const ApplicationLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 22px;
  width: 173px;
  height: 173px;
  border-radius: 50%;
  text-align: center;
  margin-top: ${({ theme }) => theme.grid?.gridGutterMedium};
  background-color: ${({ theme }) => theme.colors?.primary};
  color: ${({ theme }) => theme.colors?.white};
  @media ${({ theme }) => theme.media?.large} {
    margin: 0;
  }
`;

export const ApplicationLabelFirstPrice = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 36.3086px;
  line-height: 43px;
`;

export const ApplicationLabelFirstText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14.9506px;
  line-height: 19px;
  margin: 0;
`;

export const ApplicationLabelLine = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const ApplicationLabelPrice = styled.div<{ old?: boolean }>`
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 25.6296px;
  line-height: 34px;
  &:first-child {
    color: #c6efdc;
  }
  &::before {
    ${(props) => (props.old ? 'content: "";' : "")}
    position: absolute;
    display: block;
    width: 123%;
    height: 1px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors?.error};;
    -webkit-transform: rotate(-40deg);
    -ms-transform: rotate(-40deg);
    transform: rotate(-25deg);
    top: 50%;
    left: -4px;
  }
  &::after {
    ${(props) => (props.old ? 'content: "";' : "")}
    position: absolute;
    display: block;
    width: 123%;
    height: 1px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors?.error};;
    transform: rotate(25deg);
    top: 50%;
    left: -4px;
  }
`;

export const ApplicationLabelInfo = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 10.679px;
  line-height: 13px;
  margin: 4px 0 0 0;
  text-transform: uppercase;
`;

export const ApplicationRow = styled.div`
  @media ${({ theme }) => theme.media?.extraSmall} {
    &.hide-in-mobile {
      display: none;
    }
  }
  @media ${({ theme }) => theme.media?.medium} {
    width: max-content;
    margin: auto;
    display: flex;
    align-items: center;
    &.hide-in-mobile {
      display: flex;
    }
  }
  @media ${({ theme }) => theme.media?.large} {
    // justify-content: space-around;
    display: flex;
    align-items: center;
  }
`;

export const ApplicationRating = styled.div`
  text-align: center;
  margin-top: ${({ theme }) => theme.grid?.gridGutterMedium};
  svg {
    width: 180px !important;
  }

  @media ${({ theme }) => theme.media?.extraSmall} {
    &.show-in-mobile {
      display: block;
    }
    &.hide-in-desktop {
      margin-top: 12px;
    }
  }
  @media ${({ theme }) => theme.media?.medium} {
    margin-left: 20px;
  }
  @media ${({ theme }) => theme.media?.large} {
    margin-left: 0px;
    &.show-in-mobile {
      display: none !important;
    }
  }
`;

export const ApplicationRatingText = styled.p`
  color: #85a8e7;
  font-style: normal;
  font-weight: 400;
  font-size: 18.2266px;
  line-height: 23px;
  // color: ${({ theme }) => theme.colors?.darkGrey};
  margin: 0;
  @media ${({ theme }) => theme.media?.extraSmall} {
    padding-bottom: 12px;
    font-size: 14px;
  }
  @media ${({ theme }) => theme.media?.medium} {
    padding-bottom: 0px;
    font-size: 18.2266px;
  }
`;

export const ApplicationList = styled.ul`
  padding: ${({ theme }) => theme.grid?.gridGutter};
  border: 1px solid ${({ theme }) => theme.colors?.grey};
  border-radius: 8px;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;
  margin-top: ${({ theme }) => theme.grid?.gridGutter};
  li {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    svg {
      margin-right: 3px;
    }
  }
  @media ${({ theme }) => theme.media?.extraSmall} {
    display: none;
  }
  @media ${({ theme }) => theme.media?.medium} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: ${({ theme }) => theme.grid?.gridGutterMedium};
  }
`;

// unbounce

export const UnbounceTitle = styled.h1`
  color: rgb(51, 204, 153) !important;
  text-align: center;

  margin: 0;

  font-family: Montserrat;
  font-weight: 800;

  @media ${({ theme }) => theme.media?.extraSmall} {
    font-size: 25px;
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: 12px !important;
  }
  @media ${({ theme }) => theme.media?.medium} {
    font-size: 30px;
    line-height: 40px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 19px !important;
  }
  @media ${({ theme }) => theme.media?.large} {
    font-size: 40px;
    line-height: 50px;
  }
`;

export const UnbounceApplicationText = styled.p`
  line-height: 29px;
  text-align: center;
  font-weight: bolder;
  font-family: Montserrat;
  // font-size: 26px;
  color: rgb(110, 114, 119);

  @media ${({ theme }) => theme.media?.extraSmall} {
    font-size: 22px;
    max-width: 260px;
    margin: auto;
  }

  @media ${({ theme }) => theme.media?.large} {
    font-size: 26px;
    max-width: 100%;
    margin: 26px 0px;
  }
`;

export const UnbouncedApplicationList = styled.ul`
  padding: ${({ theme }) => theme.grid?.gridGutter};
  border: 2px solid #05b674;
  background-color: #f8f8f8;
  border-radius: 8px;
  display: grid;

  grid-template-columns: 1fr;
  margin-top: ${({ theme }) => theme.grid?.gridGutter};

  li {
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    svg {
      margin-right: 3px;
    }
  }
  li {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 5px;
    &:nth-last-child(1),
    &:nth-last-child(2) {
      margin-bottom: 0px;
    }
    svg {
      margin-right: 8px;
      border-radius: 2px;
      padding: 3px;
      background-color: #05b674;
      width: 20px;
      height: 20px;
      path {
        fill: white !important;
      }
    }
  }
  @media ${({ theme }) => theme.media?.extraSmall} {
    width: 100%;
    max-width: 285px;
    margin: 0 auto;
    margin-bottom: 24px;
    grid-gap: 4px;
    grid-template-columns: 0.8fr 1fr;
    padding: 12px;
    padding-right: 4px;
    border-width: 1px;
    li {
      font-size: 12px;
      margin-bottom: 4px;
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
  @media ${({ theme }) => theme.media?.medium} {
    width: max-content;
    max-width: 100%;
    margin: 0px;
    margin: auto;
    padding: 16px;
    border-width: 2px;
    grid-gap: 8px;
    margin-top: ${({ theme }) => theme.grid?.gridGutterMedium};
    grid-template-columns: 0.8fr 1fr;
    li {
      margin-bottom: 5px;
      font-size: 15px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  @media ${({ theme }) => theme.media?.large} {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    margin-top: ${({ theme }) => theme.grid?.gridGutterMedium};
  }
`;

export const UnbouncedPositionWrapper = styled.div`
  @media ${({ theme }) => theme.media?.extraSmall} {
    margin-left: 0px;
  }

  @media ${({ theme }) => theme.media?.large} {
    // margin-left: -64px;
  }
`;

export const PointingArrowWrapper = styled.div`
  width: 100%;
  position: relative;
  &:after {
    content: "";
    width: 78px;
    height: 78px;
    position: absolute;
    left: 50%;
    top: 100%;
    // background-image: url(${!window.location.href.includes("blcrpt001") ? ArrowDesktop : ArrowDesktopBlue});
    background-size: contain;
    transform: translate(calc(100% - 8px), calc(-100% + 10px));

    transform: translate(calc(100% - 8px), calc(-100% + 10px));
  }
`;
