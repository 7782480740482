import styled from 'styled-components';

export const Label = styled.div<{ focused?: boolean }>`
    position: absolute;
    font-size: ${(props) => props.focused ? '12px' : '14px'};
    color: ${(props) => props.focused ? '#1865BA' : '#B2B1B1'};
    padding: ${(props) => props.focused ? '0' : '11px 10px'};
    top: ${(props) => props.focused ? '-10px' : '0'};
    margin-left: ${(props) => props.focused ? '10px' : '0'};
    background: ${(props) => props.focused ? '#FFF' : 'transparent'};
    pointer-events: none;
    text-transform: capitalize;
    transition: 0.2s ease all;
`;

export const Input = styled.input`
    width: 100%;
    max-width: 100%;
    height: 40px;
    padding: 11px 10px;
    font-size: 14px;
    outline: none;
    border-radius: 5px;
    border: none;
    border-bottom: 2px solid ${({theme})=> theme.colors?.grey};
    appearance: none;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    input[type=number] {
    -moz-appearance: textfield;
    }
    &::placeholder {
        color: ${({theme})=> theme.colors?.darkGrey};
        opacity: 1;
    }
    &:focus {
        border-color: ${({theme})=> theme.colors?.darkGrey};
    };
    @media ${({ theme }) => theme.media.extraSmall} {
        font-size: 16px;
    }
    @media ${({ theme }) => theme.media.medium} {
        font-size: 14px;
    }
`;

export const Icon = styled.div`
    position: absolute;
    padding: 11px 10px;
    cursor: pointer;
    right: 0;
`;