import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

(window as any).Chargebee.init({
  site: process.env.REACT_APP_SITE,
  publishableKey: process.env.REACT_APP_PUBLISHABLE_KEY
});

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById("root")
);
