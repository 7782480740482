import * as s from "./contract.styled";

import React, { useEffect, useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router";

import PrintIcon from 'assets/images/print.png';
import Loader from "components/loader";

const Contract: React.FC = () => {
  const [contract, setContract] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    showContract();
  }, []);

  const showContract = () => {
    setLoadingStatus(true);
    axios
      .get("/api/Contract", {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("userData") as any)?.accessToken?.token || ''
          }`,
        },
      })
      .then(function (response) {
        setContract(response?.data);
        setLoadingStatus(false);
      })
      .catch(function (error) {
        setLoadingStatus(false);
      });
  };

  const print = () =>{
    window.print();
  }

  return (
    <>
    {loadingStatus ? <Loader /> : ""}
    <s.MAuto onClick={()=>print()}>
      <img width="40" src={PrintIcon} alt="" />
    </s.MAuto>
    <div className="Container" dangerouslySetInnerHTML={{__html: contract||''}}></div>
    </>
  );
};

export default Contract;
