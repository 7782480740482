import * as s from './footer.styled';
import logo from 'assets/images/logo.png';
import brand from 'assets/images/bbb.png';
import { NavLink } from 'react-router-dom';

const Footer: React.FC = () => (
  <s.Footer>
    <s.Logo>
      {window.location.href.includes('user-cabinet') ?

        <a href='https://hasprostage.wpengine.com/' target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="HasPro" />
        </a>
        :
        <NavLink to={window.location.href.includes('blcrpt001')?'/blcrpt001':'/'}>
          <img src={logo} alt="HasPro" />
        </NavLink>
      }
    </s.Logo>
    <s.Copyright>© {(new Date().getFullYear())} Haspro Warranty. All Rights Reserved</s.Copyright>
    <s.Brands>
      <img src={brand} alt="HasPro"></img>
    </s.Brands>
  </s.Footer>
);

export default Footer;