import { ITheme } from './styled';

export const theme: ITheme = {
  colors: {
    primary: window.location.href.includes('blcrpt001')? '#12365E': '#07CC87',
    // primary: '#07CC87',
    secondary: '#2b2b2b',
    success: '#4caf50',
    error: '#f44336',
  
    bg: 'rgba(232, 241, 248, 0.5)',
    greyBg: 'rgba(240, 240, 240, 0.5)',
    font: '#414141',
    white: '#FFFFFF',
    black: '#000000',
    grey: '#E9E9E9',
    grey1: '#575757',
    lightBlue: '#D2E3F1',
    darkGrey: '#6D6D6D',

  },

  media: {
    extraLarge: '(min-width: 1200px)',
    large: '(min-width: 1024px)',
    medium: '(min-width: 768px)',
    small: '(min-width: 576px)',
    extraSmall: '(min-width: 280px)',
  },
  grid: {
    gridGutter: '16px',
    gridGutterSmall: '4px',
    gridGutterMedium: '32px',
    gridGutterLarge: '64px',
    gridMaxWidth: '1080px',
  },

  // in px
  sizes: {
    header: { height: 56 },
    container: { width: 1200 },
    footer: { height: 128 },
    modal: { width: 540 },
  },

  // in ms
  durations: {
    ms300: 300,
  },

  // z-index
  order: {
    header: 50,
    modal: 100,
  },
}