import * as s from './repair/repair.styled'
import Repair from './repair';
import CallToAction from './call-to-action';
import Testimonial from './testimonial';
import Benefits from './benefits';
import Application from './application';

import CarImg from "assets/images/car-img.png";

const UnbounceLanding: React.FC = () => {

  const scrollToActivationForm = () => {
    document.getElementById('activationForm')?.scrollIntoView({ behavior: 'smooth' });

  }

  return (
    <>
      <Application />
      <Repair />
      <CallToAction className="hide-in-mobile" onClick={() => scrollToActivationForm()} />
      <Testimonial />

      <CallToAction onClick={() => scrollToActivationForm()} />
      <s.RepairContent className="show-in-mobile">
          <s.UnbounceSubtitle>
            Cost of repairs without Haspro Warranty:
          </s.UnbounceSubtitle>
          <s.UnbounceRepairCosts>
            <img src={CarImg} />
          </s.UnbounceRepairCosts>
        </s.RepairContent>
      <Benefits />
      <CallToAction onClick={() => scrollToActivationForm()} />
    </>
  )
};


export default UnbounceLanding;