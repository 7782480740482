import styled from "styled-components";

export const Benefits = styled.section`
  padding: ${({ theme }) => theme.grid?.gridGutterLarge} 0
    ${({ theme }) => theme.grid?.gridGutterMedium};
  background-color: ${({ theme }) => theme.colors?.bg};
  margin-bottom: ${({ theme }) => theme.grid?.gridGutterLarge};
  overflow: hidden;
  @media ${({ theme }) => theme.media?.extraSmall} {
    margin-bottom: 0px;
  }
  @media ${({ theme }) => theme.media?.medium} {
    margin-bottom: ${({ theme }) => theme.grid?.gridGutterLarge};
  }
`;

export const BenefitsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
  margin: 0 -${({ theme }) => theme.grid?.gridGutter};
  @media ${({ theme }) => theme.media?.extraSmall} {
    padding-left: 8px;
    padding-right: 8px;
  }
  @media ${({ theme }) => theme.media?.medium} {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media ${({ theme }) => theme.media?.extraLarge} {
    margin: 0 -${({ theme }) => theme.grid?.gridGutterLarge};
  }
`;

export const BenefitsListItem = styled.li`
  width: 50%;
  padding: 0 ${({ theme }) => theme.grid?.gridGutter};
  margin-bottom: ${({ theme }) => theme.grid?.gridGutterMedium};
  @media ${({ theme }) => theme.media?.extraSmall} {
    padding: 0 8px;
  }
  @media ${({ theme }) => theme.media?.medium} {
    width: 33.33%;
    padding: 0 ${({ theme }) => theme.grid?.gridGutter};
  }
  @media ${({ theme }) => theme.media?.extraLarge} {
    padding: 0 40px;
  }
  img {
    max-height: 64px;
    margin: 0 auto;
  }
  h3 {
    border-top: 2px solid ${({ theme }) => theme.colors?.grey};
    padding-top: ${({ theme }) => theme.grid?.gridGutter};
    margin: ${({ theme }) => theme.grid?.gridGutterSmall} 0 0 0;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
  p {
    margin: ${({ theme }) => theme.grid?.gridGutterSmall} 0 0 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
`;

// unbounce
export const Additional = styled.p`
@media ${({ theme }) => theme.media?.extraSmall} {
  font-size: 10px;
  margin-bottom: 8px;
}
`

export const ShortDesc=styled.p`
  margin-bottom: 9px!important;
`

export const UnbounceBenefitsListItem = styled.li`
  width: 50%;
  padding: 0 ${({ theme }) => theme.grid?.gridGutter};
  margin-bottom: ${({ theme }) => theme.grid?.gridGutterMedium};
  
  img{
    width: 90px;
    max-height: 82px;
    object-fit: contain;
  }

  &:nth-child(3){
    img{
      width: 121px;
     max-height: 64px;
    }
  }
  &:nth-child(4){
    img{
          width: 102px;
    height: 71px
    }
  }

  @media ${({ theme }) => theme.media?.extraSmall} {
    padding: 0 8px;
    width: 100%;
  }
  @media ${({ theme }) => theme.media?.small} {
    width: 50%;
  }
  @media ${({ theme }) => theme.media?.medium} {
    width: 33.33%;
    padding: 0 ${({ theme }) => theme.grid?.gridGutter};
  }
  @media ${({ theme }) => theme.media?.extraLarge} {
    padding: 0 40px;
  }
  img {
    margin: 0 auto;
  }
  h3 {
    // border-top: 2px solid ${({ theme }) => theme.colors?.grey};
    padding-top: ${({ theme }) => theme.grid?.gridGutter};
    margin: ${({ theme }) => theme.grid?.gridGutterSmall} 0 0 0;

    line-height: 24px;
    font-family: Montserrat;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    color: rgb(76, 72, 72);
    text-align: center;
  }
  p {
    margin: ${({ theme }) => theme.grid?.gridGutterSmall} auto 0 auto;

    max-width: 276px;

    line-height: 16px;
    color: rgb(95, 96, 98);
    font-family: Montserrat;
    text-align: center;
  }
`;

export const UnbounceContainer = styled.div<{ width?: string }>`
  max-width: 980px;
  padding: 0 ${({theme}) => theme.grid?.gridGutter};
  margin: 0 auto;
  @media ${({ theme }) => theme.media?.extraSmall} {
    padding: 0 ${({theme}) => theme.grid?.gridGutterMedium};
  }
  @media ${({ theme }) => theme.media?.medium} {
     padding: 0 ${({theme}) => theme.grid?.gridGutter};
  }
`;