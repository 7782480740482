// const DEBUG = process.env.REACT_APP_NODE_ENV !== "production";
import { toast, Slide } from "react-toastify";
// import { useNavigate } from 'react-router';

const ToastrComponent = ({ message, title = "" }: any) => {
  return (
    <div className="flex flex-row items-start justify-start">
      {title ? (
        <p className="mx-4 font-medium leading-5 text-white mb-0">
          <b>{title}</b>
        </p>
      ) : (
        ""
      )}
      <p
        className={`mx-4 font-medium leading-5 text-white ${
          title ? "mt-0" : ""
        }`}
      >
        {message}
      </p>
    </div>
  );
};

const errorInterceptor = (axiosInstance: any) => {
  // const navigate = useNavigate();

  axiosInstance.interceptors.response.use(
    (response: any) => {
      //Response Successful
      return response;
    },
    (error: any) => {
      let errTitle = "";
      let errMsg = "";
      if (error?.response?.status === 500) {
        errTitle = "Internal Server Error";
        errMsg = "The server encountered something unexpected that didn`t allow it to com  the request.";
      } else if (error?.response?.status === 502) {
        errTitle = "Bad Gateway";
        errMsg = "Looks like server is experiencing an error while processing your request.";
      } else if (error?.response?.status === 504) {
        errTitle = "This page is taking way too long to load";
        errMsg = "Please try refreshing and contact us if the problem persists.";
      } else {
        errMsg = error.response?.data.message;
      }

      toast.error(<ToastrComponent title={errTitle} message={errMsg} />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        transition: Slide,
        theme: "colored",
      });
      if (error?.response.status === 401) {
        //Unauthorized
        //redirect to Login
        window.location.href = "/login";
      } else {
        //dispatch your error in a more user friendly manner
        // if (DEBUG) {
        //   //easier debugging
        //   console.group("Error");
        //   console.log(error);
        //   console.groupEnd();
        // }
      }
      throw(error)
    }
  );
};
export default errorInterceptor;
