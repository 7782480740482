import styled from "styled-components";

export const Repair = styled.section`
  margin-bottom: ${({ theme }) => theme.grid?.gridGutterLarge};
  // margin-top: ${({ theme }) => theme.grid?.gridGutterMedium};
  @media ${({ theme }) => theme.media?.extraSmall} {
    width: 320px;
    margin 0 auto;
  }
  @media ${({ theme }) => theme.media?.medium} {
    width: 100%;
    margin-bottom: ${({ theme }) => theme.grid?.gridGutterLarge};
    // margin-top: ${({ theme }) => theme.grid?.gridGutterMedium};
  }
`;

export const RepairGrid = styled.div`
  // border-top: 2px solid ${({ theme }) => theme.colors?.grey};
  // padding-top: ${({ theme }) => theme.grid?.gridGutterMedium};
  @media ${({ theme }) => theme.media?.extraSmall} {
    border-top: none;
    padding-top: 0px;
  }
  @media ${({ theme }) => theme.media?.medium} {
    // border-top: 2px solid ${({ theme }) => theme.colors?.grey};
    padding-top: ${({ theme }) => theme.grid?.gridGutterMedium};
    display: grid;
    grid-template-columns: calc(50% - 16px) calc(50% - 16px);
    grid-gap: ${({ theme }) => theme.grid?.gridGutterMedium};
  }
  @media ${({ theme }) => theme.media?.large} {
    grid-template-columns: 426px auto;
    padding-top: 0px;
  }
`;

export const RepairList = styled.ul`
  width: 100%;
  margin-bottom: 90px;
  @media ${({ theme }) => theme.media?.extraSmall} {
    margin-bottom: 45px;
  }
  @media ${({ theme }) => theme.media?.medium} {
    max-width: 426px;
    margin-bottom: 0;
  }
`;

export const RepairListItem = styled.li`
  margin-bottom: ${({ theme }) => theme.grid?.gridGutter};
  &:last-child {
    margin-bottom: 0;
  }
`;

export const RepairListTitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 8px 0;
  color: ${({ theme }) => theme.colors?.primary};
`;

export const RepairListDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
`;

export const RepairContent = styled.div`
@media ${({ theme }) => theme.media?.extraSmall} {
  display: none;
  &.show-in-mobile{
    display: block;
    margin-bottom: 36px;
  }
}

@media ${({ theme }) => theme.media?.medium} {
  display: block;
  &.show-in-mobile{
    display: none;
    margin-bottom: 0px;
  }
}
`;

export const RepairCosts = styled.div`
  position: relative;
`;

export const RepairIcon = styled.div`
  margin-top: ${({ theme }) => theme.grid?.gridGutterLarge};
  padding: 13.5%;
  svg {
    display: block;

    margin: 0 auto;
    width: 100%;
    height: auto;
    max-width: ;
  }
  @media ${({ theme }) => theme.media?.extraSmall} {
    margin-top: 38px;
  }
  @media ${({ theme }) => theme.media?.medium} {
    margin-top: ${({ theme }) => theme.grid?.gridGutterLarge};
  }
`;

//unbounce
export const UnbounceRepairListItem = styled.li`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const UnbounceRepairListTitle = styled.h3`
line-height: 32px;
  font-family: Montserrat;
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: rgb(51, 204, 153);
  font-weight: bolder;
  margin: 0px;
`;

export const UnbounceRepairListDescription = styled.p`
  line-height: 22px;
  font-family: Montserrat;
  font-weight: 300;
  font-style: normal;
  color: rgb(59, 61, 64);
  font-size: 14px;
  margin: 0;
`;


export const UnbounceRepairCosts = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  img{
    max-width: 423px;
    width:100%;
  }

  @media ${({ theme }) => theme.media.extraSmall} {
    img{
      width: 100%:
    }
  }
`;

export const UnbounceSubtitle = styled.h2`
line-height: 26px;
text-align: center;
font-size: 18px;
font-family: Montserrat;
font-weight: 300;
font-style: normal;
color: rgb(56, 182, 176);
`;