import * as s from "./repair.styled";
import Container from "components/container";
import Subtitle from "components/subtitle";
import Icon from "components/icons";
import RepairCost from "../repair-cost";

import CarImg from "assets/images/car-img.png";

const Repair: React.FC = () => (
  <s.Repair>
    <Container>
      <s.RepairGrid>
        <s.RepairList>
          {data.map(({ title, description }, index) => (
            <s.UnbounceRepairListItem key={index}>
              <s.UnbounceRepairListTitle>{title}</s.UnbounceRepairListTitle>
              <s.UnbounceRepairListDescription>
                {description}
              </s.UnbounceRepairListDescription>
            </s.UnbounceRepairListItem>
          ))}
        </s.RepairList>
        <s.RepairContent className="hide-in-mobile">
          <s.UnbounceSubtitle>
            Cost of repairs without Haspro Warranty:
          </s.UnbounceSubtitle>
          {/* <s.RepairCosts>
          <s.RepairIcon>
            <Icon.Car/>
          </s.RepairIcon>
          {
            repair.map(({title, price, left, top, position}, index) => 
                <RepairCost left={left} top={top} key={index} position={position}>{price}</RepairCost>
            )
          }
        </s.RepairCosts> */}
          <s.UnbounceRepairCosts>
            <img src={CarImg} />
          </s.UnbounceRepairCosts>
        </s.RepairContent>
      </s.RepairGrid>
    </Container>
  </s.Repair>
);

export default Repair;

const data = [
  {
    "title": "Did you know?",
    "description": "50% of car buyers purchase a warranty at the dealership. Most of the other 50% don’t buy since it’s out of their budget.",
  },
  {
    "title": "How is it only $9.99/month you might ask?",
    "description": "Obviously it is not a bumper to bumper plan. It's a practical plan to fit all vehicles. We simply cover every item we list.",
  },
  {
    "title": "Who is this plan for?",
    "description": "If you are someone who never breaks down and takes great care of your vehicle this is for you!",
  },
  // {
  //   "title": "Can I get a plan that covers more?",
  //   "description": "Yes, This particular plan is for buyers not interested in full coverage, fill out the form above and we will send you a quote.",
  // },
]

const repair = [
  {
    title: "Towing",
    price: "$150",
    top: 51.5,
    left: 0,
    position: "bottom-right",
  },
  {
    title: "Electrical",
    price: "$550",
    top: 29,
    left: 2,
    position: "bottom-right",
  },
  {
    title: "Glass",
    price: "$500",
    top: 6,
    left: 52,
    position: "bottom-left",
  },
  {
    title: "Brake System",
    price: "$750",
    top: 47,
    left: 84,
    position: "bottom-left",
  },
  {
    title: "Car Rental",
    price: "$275",
    top: 82,
    left: 80,
    position: "top-left",
  },
  {
    title: "Trip Interruption",
    price: "$350",
    top: 82,
    left: 52,
    position: "top-left",
  },
  {
    title: "Flat Tire Replacement",
    price: "$250",
    top: 82,
    left: 6,
    position: "top-right",
  },
];
