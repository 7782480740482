import styled from "styled-components";

export const ComparisonChart = styled.section`
  margin-bottom: ${({ theme }) => theme.grid.gridGutter};
  padding: ${({ theme }) => theme.grid.gridGutterLarge} 0
    ${({ theme }) => theme.grid.gridGutterLarge};
  background-color: ${({ theme }) => theme.colors.bg};
  @media ${({ theme }) => theme.media.extraSmall} {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  @media ${({ theme }) => theme.media.medium} {
    padding: ${({ theme }) => theme.grid.gridGutterLarge} 0
      ${({ theme }) => theme.grid.gridGutterLarge};
    background-color: ${({ theme }) => theme.colors.bg};
  }
`;

export const TableWrapper = styled.div`
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid rgba(18, 18, 18, 0.1);
  @media ${({ theme }) => theme.media?.extraSmall} {
    overflow-x: auto;
    border-width: 2px;
  }
  @media ${({ theme }) => theme.media?.medium} {
    overflow-x: hidden;
    border-width: 1px;
  }
`;

export const Table = styled.table`
  width: calc(100% + 2px);
  border: 1px solid rgba(18, 18, 18, 0.1);
  border-spacing: 0px;
  border-collapse: collapse;
  border-radius: 12px;
  // overflow: hidden;
  margin: -1px;
  background-color: ${({ theme }) => theme.colors.white};
  @media ${({ theme }) => theme.media?.extraSmall} {
    border-collapse: separate;
    width: calc(100% + 4px);
    margin: -2px;
  }
  @media ${({ theme }) => theme.media?.medium} {
    border-collapse: collapse;
  }
`;

export const Th = styled.th`
  text-align: left;
  padding: 12px;
  border: 1px solid rgba(18, 18, 18, 0.1);
  border-collapse: collapse;
  color: #8a8a8a;
  
  &:not(:first-child) {
    width: 182px;
    text-align: center;
  }
  &:last-child {
    background-color: #cdf5e7;
    color: ${({ theme }) => theme.colors.primary};
  }

  @media ${({ theme }) => theme.media?.extraSmall} {
    border-collapse: separate;
    &:first-child{
      position: sticky!important;
      left: -1px;
      z-index: 2;
      background: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const Td = styled.td<{ mode?: "light" | "primary"; wrap?: "no" }>`
  padding: 12px;
  border: 1px solid rgba(18, 18, 18, 0.1);
  border-collapse: collapse;
  &:not(:first-child) {
    text-align: center;
  }
  white-space: ${(props) => (props.wrap === "no" ? "nowrap" : "")};
  color: ${(props) =>
    props.mode === "light"
      ? "#8A8A8A"
      : props.mode === "primary"
      ? ({ theme }) => theme.colors.primary
      : "inherit"};
  &:last-child {
    background-color: #cdf5e7;
  }

  @media ${({ theme }) => theme.media?.extraSmall} {
    border-collapse: separate;
    &:first-child{
      position: sticky!important;
      left: -1px;
      z-index: 2;
      background: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const Accordion = styled.div``;

export const Rotate = styled.span<{ rotate?: true | false }>`
  display: block;
  transform: ${(props) => (props.rotate ? "rotate(180deg)" : "rotate(0deg)")};
  margin-bottom: ${(props) => (props.rotate ? "-4px" : "0px")};
`;

export const AccordionBody = styled.div`
  max-height: 0px;
  overflow: hidden;
  transition: 0.2s;
  &.active {
    max-height: max-content;
  }
`;

export const AccordionHeader = styled.h3`
  * {
    display: inline-block;
  }
  cursor: pointer;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #8a8a8a;
  margin-top: 24px;
  margin-bottom: 24px;
  width: max-content;
  span {
    margin-left: ${({ theme }) => theme.grid.gridGutter};
  }
  @media ${({ theme }) => theme.media?.extraSmall} {
    width: 100%;
    .show-in-mobile {
      display: block;
    }
  }

  @media ${({ theme }) => theme.media?.medium} {
    width: max-content;
    .show-in-mobile {
      display: none !important;
    }
  }

  @media ${({ theme }) => theme.media?.large} {
    .show-in-mobile {
      display: none !important;
    }
  }
`;
