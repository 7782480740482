import styled from "styled-components";

export const MAuto = styled.div`
  margin: 24px auto;
  width: max-content;
  cursor: pointer;
  @media print{
    display: none
  }
`
